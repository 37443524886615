








import Component from "vue-class-component";
import Vue from "vue";
import AppCollectionItem from "../../components/collections/AppCollectionItem.vue";
import {Prop} from "vue-property-decorator";
import {CollectionItem} from "@/store/modules/strapi/types";

export interface AppCollectionItemsFilter {
  categorySlug: string | null;
}

@Component({
  components: {
    AppCollectionItem
  }
})
export default class AppCollectionItems extends Vue {
  @Prop() readonly displayItems!: Array<CollectionItem>;
  @Prop() readonly itemsFilter!: AppCollectionItemsFilter;

  get items(){
    return this.displayItems
              .filter(this.filterByCategory);
  }

  private filterByCategory(item: CollectionItem): boolean {
    if(this.itemsFilter.categorySlug && this.itemsFilter.categorySlug.length > 0){
      return item.categories.some(c => c.slug === this.itemsFilter.categorySlug);
    }
    return true;
  }
}
