



























import Vue from "vue";
import Component from "vue-class-component";
import {Strapi} from "@/store/modules/strapi/service";
import {Watch} from "vue-property-decorator";
import {Category, CollectionQuery} from "@/store/modules/strapi/types";
import {ApolloQueryResult} from "@apollo/client/core";
import AppSlideshow from "@/components/slideshow/AppSlideshow.vue";
import {MetaInfo} from "vue-meta";

@Component({
  components: {AppSlideshow},
  metaInfo(): MetaInfo {
    return (this as CollectionPreviewView).metaTags;
  }
})
export default class CollectionPreviewView extends Vue {
  collectionQuery: ApolloQueryResult<CollectionQuery> | null = null;

  get metaTags(): MetaInfo {
    return {
      title: this.collection?.name,
      meta: [
        { name: 'description', content: `Scopri la collezione${this.collection ? ' ' + this.collection.name: ''}.`  }
      ]
    };
  }

  async beforeCreate() {
    this.collectionQuery = await Strapi.instance().fetchCollection(this.$route.params.collectionSlug);
  }

  @Watch('$route.params.collectionSlug', {immediate: true})
  async onCollectionChange() {
    this.collectionQuery = await Strapi.instance().fetchCollection(this.$route.params.collectionSlug);
  }

  get collection() {
    return this.collectionQuery?.data?.collectionBySlug;
  }

  get name(){
    if(!this.$i18n.locale.startsWith('it')){
      const locale = this.collection?.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return this.collection?.name;
  }

  get categories() {
    const cats = Array.from(this.collectionQuery?.data?.collectionCategories ?? []);
    return cats.sort((a, b) => a.name.localeCompare(b.name));
  }

  categoryName(category: Category) {
    if(!this.$i18n.locale.startsWith('it')){
      const locale = category.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return category.name;
  }
}
