











































import Vue from "vue";
import Component from "vue-class-component";
import {Strapi} from "@/store/modules/strapi/service";
import {ApolloQueryResult} from "@apollo/client/core";
import {Category, CollectionQuery} from "@/store/modules/strapi/types";
import AppCollectionItems, {AppCollectionItemsFilter} from "@/components/collections/AppCollectionItems.vue";
import AppCollectionsCategorySelector from "@/components/collections/AppCollectionsCategorySelector.vue";
import {Watch} from "vue-property-decorator";
import {MetaInfo} from "vue-meta";

@Component({
  components: {AppCollectionsCategorySelector, AppCollectionItems},
  metaInfo(): MetaInfo {
    return (this as AppCollection).metaTags;
  }
})
export default class AppCollection extends Vue {

  collectionQuery: ApolloQueryResult<CollectionQuery> | null = null;
  selectedCategory: string|null = null;
  expandCategories = false;

  get metaTags(): MetaInfo {
    return {
      title: this.metaTitle,
      meta: [
        { name: 'description', content: this.metaDescription  }
      ]
    };
  }

  get metaTitle() {
    return this.selectedCategoryName && this.collection ? `${this.selectedCategoryName} in ${this.collection?.name}` : undefined;
  }

  get metaDescription() {
    const def = `Scopri la nuova collezione${this.collection ? ' ' + this.collection.name: ''}.`;
    const other = this.metaTitle ? `${this.selectedCategoryName} nella collezione ${this.collection?.name}` : null;
    return other ? other : def;
  }

  async beforeCreate() {
    this.collectionQuery = await Strapi.instance().fetchCollection(this.$route.params.collectionSlug);
  }

  @Watch('$route.params.collectionSlug', { immediate: true })
  async onCollectionChange(){
    this.collectionQuery = await Strapi.instance().fetchCollection(this.$route.params.collectionSlug);
  }

  @Watch('$route.params.categorySlug', { immediate: true })
  async onCategoryChange(){
    this.selectedCategory = this.$route.params.categorySlug;
    this.expandCategories = false;
  }

  get collection(){
    return this.collectionQuery?.data?.collectionBySlug;
  }

  get itemsFilter(): AppCollectionItemsFilter {
    return {
      categorySlug: this.selectedCategory
    };
  }

  get categories() {
    const cats = Array.from(this.collectionQuery?.data?.collectionCategories ?? []);
    return cats.sort((a, b) => a.name.localeCompare(b.name));
  }

  categoryName(category: Category|null) {
    if(!this.$i18n.locale.startsWith('it')){
      const locale = category?.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return category?.name;
  }

  get selectedCategoryName(){
    const cat = this.categories.find(c => c.slug.localeCompare(this.selectedCategory ?? '') === 0);
    if(cat) {
      return this.categoryName(cat);
    }
    return null;
  }

  get collectionName(){
    if(!this.$i18n.locale.startsWith('it')){
      const locale = this.collection?.localizations.find(l => l.locale.startsWith('en'));
      if(locale){
        return locale.name;
      }
    }
    return this.collection?.name;
  }
}
