




















import Component from "vue-class-component";
import Vue from "vue";
import {CollectionItem} from "@/store/modules/strapi/types";
import {Prop} from "vue-property-decorator";

@Component({
  components: {}
})
export default class AppCollectionItem extends Vue {
  @Prop() readonly item!: CollectionItem;

  readonly imageServer = Object.freeze(process.env.VUE_APP_API_URI);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSrcset(formats: any): string{
    const srcset: string[] = [];
    for(const size in formats){
      // eslint-disable-next-line no-prototype-builtins
      if(!size.startsWith('l') && formats.hasOwnProperty(size)){
        srcset.push(
            `${this.imageServer}${formats[size].url} ${Math.round(formats[size].width*2.5)}w`
        );
      }
    }

    return srcset.join(',\n');
  }

  get appendUrl(){
    return !this.$route.path.endsWith('/');
  }
}
