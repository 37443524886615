





























import Vue from "vue";
import Component from "vue-class-component";
import {VModel, Prop} from "vue-property-decorator";
import {Category} from "@/store/modules/strapi/types";

@Component({
  components: {}
})
export default class AppCollectionsCategorySelector extends Vue {
  @Prop() categories!: Category[];
  @VModel({ type: String }) selectedCategory!: string;

  showCategorySelector = false;
  private panelSubsections = Object.freeze(3);
  private defaultCategory = Object.freeze({ name: 'In evidenza', slug: 'in-evidenza' })

  get category() {
    return this.categories.find(c => c.slug === this.categorySlug) ?? this.defaultCategory;
  }

  get noCategorySelected() {
    return !this.$route.params.categorySlug;
  }

  get categorySlug(){
    const selection = this.$route.params.categorySlug ?? this.defaultCategory.slug;
    this.selectedCategory = selection;
    return selection;
  }

  get pagedCategories(): Category[][]{

    if(!this.categories){
      return [];
    }

    const len = this.categories.length;
    const out: Category[][] = [];
    let i = 0;

    const size = Math.floor(len < this.panelSubsections ? len : len / this.panelSubsections);

    while (i < len) {
      out.push(this.categories.slice(i, i += size));
    }
    return out;
  }
}
